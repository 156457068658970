import { graphql, Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React, { useEffect, useRef, useState } from "react";
import { useInViewport } from 'react-in-viewport';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

import Delivery from "../components/home/delivery/delivery";
import Features from "../components/home/features/features";
import ContactFormHome from './../components/home/contactFormHome/contactFormHome';
import Footer from "./../components/home/footer/footer";
import Header from "./../components/home/header/header";
import HowItWorks from './../components/home/how-it-works/how-it-works';
import News from "./../components/home/news/news";
import PagesList from "./../components/home/pages-list/pages-list";
import Partners from "./../components/home/partners/partners";
import Pos from "./../components/home/pos/pos";
import Testimonials from "./../components/home/testimonials/testimonials";
import Top from "./../components/home/top/top";
import QrMenuMaker2 from './../components/qrMenuMaker/qrMenuMaker';
import Seo from "./../components/seo";
import testimonialsState from "./../utils/testimonials";
// import CTAModal from "../components/cta-modal/cta-modal";



// markup
const HomePage = ({ location, data }) => {

  const howItWorksRef = useRef();
  const { enterCount: howItWorksEnterCount } = useInViewport(howItWorksRef, {}, { disconnectOnLeave: false }, {});

  const posRef = useRef();
  const { enterCount: posEnterCount } = useInViewport(posRef, {}, { disconnectOnLeave: false }, {});

  const deliveryRef = useRef();
  const { enterCount: deliveryEnterCount } = useInViewport(deliveryRef, {}, { disconnectOnLeave: false }, {});

  const pagesListRef = useRef();
  const { enterCount: pagesListEnterCount } = useInViewport(pagesListRef, {}, { disconnectOnLeave: false }, {});

  const qrMenuRef = useRef();
  const { enterCount: qrMenuEnterCount } = useInViewport(qrMenuRef, {}, { disconnectOnLeave: false }, {});

  const testimonialsRef = useRef();
  const { enterCount: testimonialsEnterCount } = useInViewport(testimonialsRef, {}, { disconnectOnLeave: false }, {});

  const newsRef = useRef();
  const { enterCount: newsEnterCount } = useInViewport(newsRef, {}, { disconnectOnLeave: false }, {});

  const formRef = React.useRef(null);
  const scrollToForm = () => {
    formRef.current.scrollIntoView({ behavior: "smooth" });
  };
  const { enterCount: formEnterCount } = useInViewport(formRef, {}, { disconnectOnLeave: false }, {});

  const foterRef = useRef();
  const { enterCount: foterEnterCount } = useInViewport(foterRef, {}, { disconnectOnLeave: false }, {});

  const topProps2 = {
    title: <>Easily manage your restaurant's online orders with a single device</>,
    subtitle: <>From mom & pop shops to international chains, Orders.co has the tools to tackle the hurdles of an ever changing digital market.</>,
    button: <><Link to={'/features-overview/'}>Get Started</Link></>,
    image: (
      <>
        <StaticImage
          placeholder="none"
          loading="eager"
          alt="devices"
          src="../images/Home/HeroGraphsDesktop.png"
          className=" d-none d-md-inline-block"
        />
        <StaticImage
          placeholder="none"
          loading="eager"
          alt="devices"
          src="../images/Home/HeroTablet.png"
          className="mw-100 d-md-none"
        />
      </>
    ),
    styles: {
      // backgroundImage: `url(${heroBg})`,
      // background: 'linear-gradient(180deg, rgba(255, 107, 0, 0.4312) 6.77%, rgba(255, 107, 0, 0.098) 52.6%, rgba(255, 255, 255, 0) 90.1%)'
    },
  };

  const howItWorksProps = {
    title: <>HOW IT WORKS</>,
    subtitle: <>Few Easy Steps And Done</>,
    scrollToForm: scrollToForm,
  }

  const singleDeviceProps = {
    title: `Easily manage your restaurant's online orders with a single device`,
    image: (
      <>
        <StaticImage
          placeholder="none"
          loading="eager"
          alt="Restaurant Menu Management"
          src="../images/menu-management_tablet.png"
          className="mw-100"
        />
      </>
    ),
    scrollToForm: scrollToForm,
  };

  const pagesListProps = {
    title: <>Restaurants We Serve</>,
    subtitle: <>Explore tailored solutions for your restaurant type. </>,
    button: {
      text: <>See Pricing</>,
      to: '/pricing'
    },
    items: [
      {
        image: <>
          <StaticImage
            placeholder="none"
            loading="eager"
            alt="Chain Restaurants"
            src="../images/Home/chain-restaurants-new.png"
            className="mw-100 d-none d-xl-block"
          />
          <StaticImage
            placeholder="none"
            loading="eager"
            alt="Chain Restaurants"
            src="../images/Home/chain-restaurants-new-mb.png"
            className="mw-100 d-xl-none"
          />
        </>,
        title: <>Chain Restaurants</>,
        description: <>Manage and optimize restaurant<br /> activities across multiple locations<br /> with a centralized system.</>,
        to: "/chain-restaurants",
      },
      {
        image: <>
          <StaticImage
            placeholder="none"
            loading="eager"
            alt="Ghost Kitchen"
            src="../images/Home/ghost-kitchen-new.png"
            className="mw-100 d-none d-xl-block"
          />
          <StaticImage
            placeholder="none"
            loading="eager"
            alt="Chain Restaurants"
            src="../images/Home/ghost-kitchen-new-mb.png"
            className="mw-100 d-xl-none"
          />
        </>,
        title: <>Ghost Kitchen</>,
        description: <>Consolidate all online ordering<br /> platforms into one place.</>,
        to: "/ghost-kitchen",
      },
      {
        image: <>
          <StaticImage
            placeholder="none"
            loading="eager"
            alt="Independent Restaurants"
            src="../images/Home/independent-restaurants-new.png"
            className="mw-100 d-none d-xl-block"
          />
          <StaticImage
            placeholder="none"
            loading="eager"
            alt="Chain Restaurants"
            src="../images/Home/independent-restaurants-new-mb.png"
            className="mw-100 d-xl-none"
          />
        </>,
        title: <>Independent  Restaurants</>,
        description: <>Create a commission-free ordering website and<br /> increase revenue with direct customer sales.</>,
        to: "/independent-restaurants",
      },
      {
        image: <>
          <StaticImage
            placeholder="none"
            loading="eager"
            alt="Q-Commerce"
            src="../images/Home/q-commerce-new.png"
            className="mw-100 d-none d-xl-block"
          />
          <StaticImage
            placeholder="none"
            loading="eager"
            alt="Chain Restaurants"
            src="../images/Home/q-commerce-new-mb.png"
            className="mw-100 d-xl-none"
          />
        </>,
        title: <>Q-Commerce</>,
        description: <>Update inventory across multiple menus<br /> once with menu sync.</>,
        to: "/q-commerce",
      },
    ]
  }

  const qrMenuMaker = {
    layout: 2,
    // imageSize: "big",
    scrollToForm: scrollToForm,
    customButton: <>
      <h4>DOWNLOAD <span className="d-none d-md-inline-block">FOR FREE</span> ON</h4>
      <div className="d-flex justify-content-center mt-3 pt-0 mt-xl-2 pt-xl-1" style={{ gap: '28px' }}>
        <a className="bg-orange rounded-xl btn-shadow" href="https://play.google.com/store/apps/details?id=com.qrmenumaker" target="_blank" rel="nofollow">
          <StaticImage
            placeholder="none"
            loading="eager"
            alt="get-on-google-play"
            src="../images/QRMenu/get-on-google-play-white.png" />
        </a>
        <a className="bg-orange rounded-xl btn-shadow" href="https://apps.apple.com/us/app/qr-menu-maker/id1643857790" target="_blank" rel="nofollow">
          <StaticImage
            placeholder="none"
            loading="eager"
            alt="download-on-the-app-store"
            src="../images/QRMenu/download-on-the-app-store-white.png" />
        </a>
      </div>
    </>,
    title: (
      <div className="d-flex align-items-center justify-content-center justify-content-xl-start" style={{ gap: '20px' }}>
        <StaticImage
          placeholder="none"
          loading="eager"
          width={107}
          height={107}
          // style={{ marginLeft: '-127px' }}
          className="d-none d-md-block"
          alt="title-logo"
          src="../images/QRMenu/title-logo.png" />
        <StaticImage
          placeholder="none"
          loading="eager"
          width={55}
          height={55}
          className="d-md-none"
          alt="title-logo"
          src="../images/QRMenu/title-logo.png" />
        <div>
          QR Menu Maker App
          <br /> Fast, Easy & Free!
        </div>
      </div>
    ),
    notUseH1: true,
    subtitle: `A Digital Menu For The Digital Age`,
    image: (
      <StaticImage
        placeholder="none"
        loading="eager"
        alt="digital-menu"
        // src="../images/QRMenu/digital-menu.png"
        src="../images/Home/qr-menu-maker-new.png"
        className="mw-100"
      />
    ),
  };

  const testimonialsProps = {
    layout: 1,
    // layout: 3,
    items: [
      testimonialsState.la_fonda_home,
      testimonialsState.natalies_peruvian_home,
      testimonialsState.pulp_juice_home,
      // testimonialsState.stirred_coffee_house,
      // testimonialsState.tnt_aloha_cafe,
    ],
    title: "Hear From Our Customers",
    tag: "Testimonials",
  };


  const bp = useBreakpoint();
  const [blockHeights, setBlockHeights] = useState({
    hero: '980px',
    howItWorks: 'unset',
    pos: 'unset',
    delivery: 'unset',
    pagesList: 'unset',
    qrMenu: 'unset',
    testimonials: 'unset',
    news: 'unset',
    form: 'unset',
    foter: 'unset',
  })
  useEffect(() => {
    if (!!bp.xxl) {
      setBlockHeights({
        hero: '980px',
        howItWorks: '1000px',
        pos: '645px',
        delivery: '577px',
        pagesList: '984px',
        qrMenu: '720px',
        testimonials: '848px',
        news: '864px',
        form: '1080px',
        foter: '1080px',
      })
    } else {
      if (!!bp.xl) {
        setBlockHeights({
          hero: '980px',
          howItWorks: '973px',
          pos: '645px',
          delivery: '710px',
          pagesList: '963px',
          qrMenu: '720px',
          testimonials: '820px',
          news: '888px',
          form: '1079px',
          foter: '762px',
        })
      } else {
        if (!!bp.lg) {
          setBlockHeights({
            hero: '989px',
            howItWorks: '863px',
            pos: '645px',
            delivery: '710px',
            pagesList: '836px',
            qrMenu: '550px',
            testimonials: '848px',
            news: '911px',
            form: '1090px',
            foter: '786px',
          })
        } else {
          if (!!bp.md) {
            setBlockHeights({
              hero: '975px',
              howItWorks: '827px',
              pos: '777px',
              delivery: '975px',
              pagesList: '836px',
              qrMenu: '894px',
              testimonials: '863px',
              news: '911px',
              form: '1033px',
              foter: '1001px',
            })
          } else {
            if (!!bp.sm) {
              setBlockHeights({
                hero: '662px',
                howItWorks: '672px',
                pos: '414px',
                delivery: '380px',
                pagesList: '1132px',
                qrMenu: '894px',
                testimonials: '676px',
                news: '691px',
                form: '799px',
                foter: '527px',
              })
            } else {
              setBlockHeights({
                hero: '614px',
                howItWorks: '407px',
                pos: '532px',
                delivery: '540px',
                pagesList: '1218px',
                qrMenu: '880px',
                testimonials: '786px',
                news: '712px',
                form: '896px',
                foter: '555px',
              })
            }
          }
        }
      }
    }
  }, [bp])

  return (
    <>
      <Seo
        title={"Home"}
        description={""}
        pageSeo={data.wpPage}
        location={location}
      />
      <Header scrollToForm={scrollToForm}></Header>
      <main className="d-flex flex-column">

        <div style={{ minHeight: blockHeights?.hero ?? 'unset' }} >
          <Top {...topProps2} />
        </div>
        {bp?.md
          ? <>
            <Partners />
            <Features title="Features" />
          </>
          : <>
            <Features title="Features" />
            <Partners />
          </>}
        <div>
          <div ref={howItWorksRef} style={{ minHeight: blockHeights?.howItWorks ?? 'unset' }}>
            {howItWorksEnterCount > 0 && <HowItWorks {...howItWorksProps} />}
          </div>
          <div ref={posRef} style={{ minHeight: blockHeights?.pos ?? 'unset' }}>
            {posEnterCount > 0 && <Pos />}
          </div>
          <div ref={deliveryRef} style={{ minHeight: blockHeights?.delivery ?? 'unset' }}>
            {deliveryEnterCount > 0 && <Delivery />}
          </div>

          <div ref={pagesListRef} style={{ minHeight: blockHeights?.pagesList ?? 'unset' }}>
            {pagesListEnterCount > 0 && <PagesList {...pagesListProps} />}
          </div>
          <div ref={qrMenuRef} style={{ minHeight: blockHeights?.qrMenu ?? 'unset' }}>
            {qrMenuEnterCount > 0 && <QrMenuMaker2 useH1={false} />}
          </div>
          <div ref={testimonialsRef} style={{ minHeight: blockHeights?.testimonials ?? 'unset' }}>
            {testimonialsEnterCount > 0 && <Testimonials {...testimonialsProps} />}
          </div>
          <div ref={newsRef} style={{ minHeight: blockHeights?.news ?? 'unset' }}>
            {newsEnterCount > 0 && <News />}
          </div>
          <div ref={formRef} style={{ minHeight: blockHeights?.form ?? 'unset' }}>
            {formEnterCount > 0 &&
              <ContactFormHome
                titleStyle="small"
                title={
                  <span>
                    Experience Rapid Revenue Growth Starting From The First Month
                  </span>
                }
              ></ContactFormHome>
            }
          </div>
          <div
            className="bg-mobile"
            style={{
              background:
                "linear-gradient(180deg, #FFF8ED 0%, rgba(255, 248, 237, 0.5) 100%)",
            }}
          >
          </div>
        </div>
      </main>
      <div ref={foterRef} style={{ minHeight: blockHeights?.foter ?? 'unset' }}>
        {foterEnterCount > 0 && <Footer scrollToForm={scrollToForm}></Footer>}
      </div>
    </>
  );
};

export const query = graphql`
  query {
    wpPage(slug: { eq: "home" }) {
      title
      id
      seo {
        metaKeywords
        metaDesc
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        title
        twitterDescription
        twitterTitle
        opengraphSiteName
        opengraphTitle
        opengraphUrl
        focuskw
        opengraphImage {
          localFile {
            url
            childImageSharp {
              fixed {
                src
                width
                height
              }
            }
            extension
          }
        }
        twitterImage {
          localFile {
            url
            childImageSharp {
              fixed {
                src
                width
                height
              }
            }
            extension
          }
        }
        opengraphType
      }
    }
  }
`;

export default HomePage;
