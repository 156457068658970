import { Link } from "gatsby";
import React from "react";
import * as s from "./pages-list.module.css";

const PagesList = ({ title, subtitle, button, items }) => {

  const getItemLayout = (item) => {

    let content = <>
      <div className={`${s.item} row h-100`}>
        <div className="col-xl-5">
          <div className={s.imageWrapper}>{item.image}</div>
        </div>
        <div className="col-xl-7 d-flex flex-column justify-content-between pr-xl-0">
          <div>
            {!!item.title && <h5 className={s.itemTitle}>{item.title}</h5>}
            {!!item.description && <div className={s.description}>{item.description}</div>}
          </div>
          {!!item.to && <span className={s.more}>Learn  More &#10132;</span>}
        </div>
      </div>
    </>;

    let layout = <></>;

    if (!!item.to) {
      layout = (
        <Link to={item.to} className="text-decoration-none h-100 d-block">{content}</Link>
      );
    } else {
      layout = content;
    }

    return layout;
  }

  return (
    <>
      <section className={s.pagesList}>
        <div className="container">
          <div className="row">
            <div className="col-12">
              {!!title &&
                <h2 className={s.title}>
                  {title}
                </h2>
              }
              {!!subtitle &&
                <div className={s.subtitle}>
                  {subtitle}
                </div>
              }
            </div>
          </div>
          <div className="row justify-content-center mt-xl-5 pt-xl-5 mt-5">
            {items?.length > 0 && items.map((item, i) => {
              return <div className="d-flex justify-content-center col-12 col-md-6 col-lg-5 col-xl-6 mb-4 pb-2 mb-xl-5 pb-xl-5" key={i}>
                {getItemLayout(item)}
              </div>
            })}
          </div>
          {!!button?.to &&
            <div className="d-flex justify-content-center">
              <Link to={button.to} className={s.btn}>{button.text}</Link>
            </div>
          }
        </div>
      </section>

    </>
  );
};

export default PagesList;
