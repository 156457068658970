import { Link } from 'gatsby';
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import Slider from "react-slick";
import * as s from "./testimonials.module.css";

const Testimonials = ({ items, title, titleImg, tag, layout }) => {
  const testimonialsSettingsV1 = {
    dots: true,
    slidesToShow: 3,
    // prevArrow: <SlickArrowLeft />,
    // nextArrow: <SlickArrowRight />,
    arrows: false,
    responsive: [
      {
        breakpoint: 1399.9,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 991.9,
        settings: {
          slidesToShow: 1,
          // arrows: false,
        },
      },
    ],
  };
  return (
    <>
      <section className={`${s.testimonials} ${s.v1} testimonials v1`}>
        <StaticImage
          placeholder="none"
          loading="eager"
          alt="Form bg"
          src="./images/bg.png"
          className={`${s.bg} d-md-none`}
        />
        <div className="container">
          <div className="row">
            <div className="col-12">
              {!!tag && <div className={`${s.testimonialsTag}`}>{tag}</div>}
              <h2 className={`${s.testimonialsTitle}`}>{title}</h2>
            </div>
          </div>
        </div>
        <div className="container px-0 px-md-3">
          <Slider
            {...testimonialsSettingsV1}
            className={`testimonials-slider arrows-top`}
          >
            {items.map((t, i) => {
              return (
                <div className="row mx-0 h-100" key={`testimonial-${i}`}>
                  {(!!t.badges && t.badges?.length > 0)
                    ? <div className="col-12 mb-3 px-0 px-md-3 d-flex justify-content-center">
                      <div className={`${s.testimonialBadges} align-items-stretch`}>
                        {t.badges?.map((b, j) => {
                          return <div className={s.badgeItem} key={`badge-${j}`}>
                            {!!b.title && <div className={s.badgeTitle}>{b.title}</div>}
                            {!!b.text && <div className={s.badgeText}>{b.text}</div>}
                          </div>
                        })}
                      </div>
                    </div>
                    : <>
                      {t.badge && <div className="col-12 mb-3">
                        <div className={`${s.testimonialBadge}`}>
                          <div className={`${s.testimonialBadgeContent}`}>{t.badge}</div>
                        </div>
                      </div>}
                    </>
                  }
                  <div className="col-12 mb-1">
                    <div className={`${s.testimonialMedia} text-center`}>
                      {t.image}
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="d-flex flex-column align-items-md-center h-100">
                      {!t.to &&
                        <h4 className={`${s.testimonialAuthor}`}>{t.author}</h4>
                      }
                      <div className={`${s.testimonialsText}`}>{t.text}</div>
                      {!!t.to &&
                        <Link to={t.to} className="text-decoration-none">
                          <h4 className={`${s.testimonialAuthor}`}>{t.author}</h4>
                        </Link>
                      }
                      <span className={`${s.testimonialPosition}`}>
                        {t.position}
                      </span>
                      {/* <a href={`#${t.author}`} className={`${s.moreLink}`}>
                          <span className="mr-2">Learn More</span>
                          <svg
                            width="17"
                            height="19"
                            viewBox="0 0 17 19"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M2 8.25684C1.30964 8.25684 0.75 8.81648 0.75 9.50684C0.75 10.1972 1.30964 10.7568 2 10.7568L2 8.25684ZM15.8839 10.3907C16.372 9.90256 16.372 9.11111 15.8839 8.62295L7.92893 0.668001C7.44078 0.179845 6.64932 0.179845 6.16116 0.668001C5.67301 1.15616 5.67301 1.94761 6.16116 2.43577L13.2322 9.50683L6.16117 16.5779C5.67301 17.0661 5.67301 17.8575 6.16117 18.3457C6.64932 18.8338 7.44078 18.8338 7.92893 18.3457L15.8839 10.3907ZM2 10.7568L15 10.7568L15 8.25683L2 8.25684L2 10.7568Z"
                              fill="#FF6B00"
                            />
                          </svg>
                        </a> */}
                    </div>
                  </div>
                </div>
              );
            })}
          </Slider>
        </div>
      </section>
    </>
  );
};

export default Testimonials;
