// extracted by mini-css-extract-plugin
export var btn = "pages-list-module--btn--fc958";
export var description = "pages-list-module--description--57b95";
export var imageWrapper = "pages-list-module--image-wrapper--e656b";
export var item = "pages-list-module--item--acef9";
export var itemTitle = "pages-list-module--item-title--72f9e";
export var more = "pages-list-module--more--ce7f8";
export var number = "pages-list-module--number--e0e0c";
export var pagesList = "pages-list-module--pages-list--a6cc2";
export var subtitle = "pages-list-module--subtitle--b1e5c";
export var title = "pages-list-module--title--66c3c";