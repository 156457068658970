// extracted by mini-css-extract-plugin
export var badgeItem = "testimonials-module--badge-item--79e81";
export var badgeText = "testimonials-module--badge-text--0adb7";
export var badgeTitle = "testimonials-module--badge-title--5d4a3";
export var bg = "testimonials-module--bg--fcfae";
export var moreLink = "testimonials-module--more-link--cce3c";
export var testimonialAuthor = "testimonials-module--testimonial-author--d2c75";
export var testimonialBadge = "testimonials-module--testimonial-badge--1ccff";
export var testimonialBadgeContent = "testimonials-module--testimonial-badge-content--5d847";
export var testimonialBadges = "testimonials-module--testimonial-badges--39b60";
export var testimonialMedia = "testimonials-module--testimonial-media--603de";
export var testimonialPosition = "testimonials-module--testimonial-position--cc8d5";
export var testimonials = "testimonials-module--testimonials--eb496";
export var testimonialsTag = "testimonials-module--testimonials-tag--c6988";
export var testimonialsText = "testimonials-module--testimonials-text--b3f79";
export var testimonialsTitle = "testimonials-module--testimonials-title--c626a";