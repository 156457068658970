import React from "react";
import * as s from "./pos.module.css";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

const Pos = () => {

  return (
    <>
      <section className={`${s.pos}`}>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2 className={s.title}>Over 30 POS Integrations</h2>
            </div>
            <div className="col-xl-11 mx-auto">
              <div className={s.posItems}>
                <span className={s.posItem}>
                  <StaticImage
                    placeholder="none"
                    loading="eager"
                    alt="clover"
                    src="./images/clover.png"
                    className={`${s.img}`}
                  />
                </span>
                <span className={s.posItem}>
                  <StaticImage
                    placeholder="none"
                    loading="eager"
                    alt="square"
                    src="./images/square.png"
                    className={`${s.img}`}
                  />
                </span>
                <span className={s.posItem}>
                  <StaticImage
                    placeholder="none"
                    loading="eager"
                    alt="par-brink-pos"
                    src="./images/par-brink-pos.png"
                    className={`${s.img}`}
                  />
                </span>
                <span className={s.posItem}>
                  <StaticImage
                    placeholder="none"
                    loading="eager"
                    alt="thrive-pizza"
                    src="./images/thrive-pizza.png"
                    className={`${s.img}`}
                  />
                </span>
                <span className={s.posItem}>
                  <StaticImage
                    placeholder="none"
                    loading="eager"
                    alt="olo"
                    src="./images/olo.png"
                    className={`${s.img}`}
                  />
                </span>
                <span className={s.posItem}>
                  <StaticImage
                    placeholder="none"
                    loading="eager"
                    alt="omnivore"
                    src="./images/omnivore.png"
                    className={`${s.img}`}
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className={s.showMore}>
          <Link to="/integrations">See More</Link>
        </div>
      </section>
    </>
  );
};

export default Pos;
