import { Link } from "gatsby";
import React from "react";
import { useState } from "react";
import { slide as Menu } from "react-burger-menu";
import * as s from "./mobile-menu.module.css";
import TrialButton from "./../../trialButton/trialButton";

var styles = {
  bmBurgerButton: {
    position: "absolute",
    width: "30px",
    height: "24px",
    right: "36px",
    top: "28px",
  },
  bmBurgerBars: {
    background: "#000",
    height: "3px",
  },
  bmBurgerBarsHover: {
    background: "#ffffff",
  },
  bmCrossButton: {
    top: "20px",
    right: "20px",
    height: "28px",
    width: "28px",
  },
  bmCross: {
    height: "28px",
    background: "#000",
  },
  bmMenuWrap: {
    position: "fixed",
    top: "0",
    height: "100%",
  },
  bmMenu: {
    background: "#FFECDE",
    padding: "60px 18px 0px 18px",
    // fontSize: '1.15em'
  },
  bmMorphShape: {
    fill: "#373a47",
  },
  bmItemList: {
    color: "#b8b7ad",
    padding: "0.8em",
  },
  bmItem: {
    display: "flex",
    flexDirection: "columns",
    color: "#fff",
  },
  bmOverlay: {
    top: "0",
    background: "rgba(0, 0, 0, 0.3)",
  },
};

const MobileNav = ({ menuItems }) => {
  const [opened, setOpened] = useState([]);

  const toggleSubMenu = i => {
    let newOpened;
    if (isOpened(i)) {
      newOpened = opened.filter(o => o !== i);
    } else {
      newOpened = [...opened, i];
    }
    // console.log(newOpened);
    setOpened(newOpened);
  };

  const isOpened = i => {
    return opened.some(o => o === i);
  };

  return (
    <Menu className="mobileNav" right styles={styles}>
      <div className={`d-flex flex-column pb-4`}>
        <TrialButton
          scrollToForm={"#"}
          classes={["bg-orange cursor-pointer mb-3", s.trialButton]}
        >
          Schedule A Free Demo
        </TrialButton>
        <a
          href="tel:+1-818-452-2822"
          className={`bg-green-alt ${s.phoneButton}`}
        >
          <svg
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M20.9537 16.0626C20.1653 14.5721 17.4273 12.9559 17.3068 12.8852C16.9549 12.685 16.5878 12.579 16.2442 12.579C15.7334 12.579 15.3152 12.813 15.062 13.2386C14.6614 13.7177 14.1647 14.2776 14.0442 14.3644C13.1112 14.9973 12.3809 14.9255 11.5728 14.1174L7.06281 9.60697C6.25984 8.80399 6.18602 8.06463 6.81466 7.13679C6.90261 7.01546 7.46253 6.51836 7.94157 6.11746C8.24706 5.93566 8.45673 5.66551 8.54861 5.33412C8.67073 4.89317 8.58081 4.37447 8.2926 3.86913C8.22468 3.7529 6.60773 1.01453 5.118 0.226478C4.84 0.0792331 4.52666 0.00148773 4.21254 0.00148773C3.69502 0.00148773 3.20813 0.203312 2.84218 0.568872L1.84563 1.56503C0.269515 3.14075 -0.30101 4.92693 0.148971 6.87371C0.524348 8.49615 1.61828 10.2227 3.40093 12.0049L9.1749 17.7789C11.4311 20.0351 13.582 21.1793 15.5681 21.1793C17.0291 21.1793 18.3905 20.5585 19.6148 19.3346L20.6109 18.3384C21.2164 17.7333 21.3538 16.8184 20.9537 16.0626Z"
              fill="#F1F6FE"
            />
            <path
              d="M11.607 9.78728C11.6549 9.80731 11.7059 9.81752 11.757 9.81752H16.4688C16.6856 9.81752 16.8615 9.642 16.8615 9.42486C16.8615 9.20773 16.6856 9.03221 16.4688 9.03221H12.7048L18.7097 3.02736C18.8632 2.87383 18.8632 2.62568 18.7097 2.47215C18.5562 2.31862 18.308 2.31862 18.1545 2.47215L12.1496 8.477V4.71302C12.1496 4.49588 11.9737 4.32037 11.757 4.32037C11.5402 4.32037 11.3643 4.49588 11.3643 4.71302V9.42486C11.3643 9.47591 11.3749 9.52695 11.3946 9.57486C11.4346 9.67106 11.5112 9.74762 11.607 9.78728Z"
              fill="#F1F6FE"
            />
          </svg>
          <span>Call Now</span>
        </a>
        {menuItems.map((mi, i) => {
          let hasSubMenu = !!mi.items && mi.items.length > 0;
          return (
            <div
              key={`header-menu-item-${i}`}
              className={`${isOpened(i) ? s.opened : ""} ${s.menuItem} ${
                hasSubMenu ? s.hasSubmenu : ""
              } mb-3`}
            >
              {hasSubMenu && (
                <>
                  <span
                    onClick={() => {
                      toggleSubMenu(i);
                    }}
                  >
                    {mi.title}
                  </span>
                  <div className={`${s.subMenu}`}>
                    {mi.items.map((smi, j) => (
                      <div
                        key={`header-submenu-item-${i}-${j}`}
                        className={`${s.menuItem}`}
                      >
                        {!!smi.to && <Link to={smi.to}>{smi.title}</Link>}
                        {!smi.to && <span>{smi.title}</span>}
                      </div>
                    ))}
                  </div>
                </>
              )}
              {!hasSubMenu && !!mi.to && (
                <>
                  {!!mi.target && mi.target === "_blank" && (
                    <a href={mi.to} rel="noreferrer" target="_blank">
                      {mi.title}
                    </a>
                  )}
                  {!mi.target && <Link to={mi.to}>{mi.title}</Link>}
                </>
              )}
            </div>
          );
        })}
      </div>
    </Menu>
  );
};

export default MobileNav;
