import React from "react";
import * as s from "./partners.module.css";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

const Partners = () => {

  const [opened, setOpened] = React.useState(false);

  return (
    <>
      <section className={`${s.partners} ${opened ? s.opened : ''}`}>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2 className={s.title}>Our Restaurant Partners</h2>
            </div>
            <div className="col-xl-11 mx-auto">
              <div className={s.partnersItems}>
                <span className={s.partnersItem}>
                  <StaticImage
                    placeholder="none"
                    loading="eager"
                    alt="hummus republic"
                    src="./images/hummus-republic.png"
                    className="w-auto"
                  />
                </span>
                <span className={s.partnersItem}>
                  <StaticImage
                    placeholder="none"
                    loading="eager"
                    alt="colette"
                    src="./images/colette.png"
                    className="w-auto"
                  />
                </span>
                <span className={s.partnersItem}>
                  <StaticImage
                    placeholder="none"
                    loading="eager"
                    alt="chocolatte-espresso-bar"
                    src="./images/chocolatte-espresso-bar.png"
                    className="w-auto"
                  />
                </span>
                <span className={s.partnersItem}>
                  <StaticImage
                    placeholder="none"
                    loading="eager"
                    alt="keftis"
                    src="./images/keftis.png"
                    className="w-auto"
                  />
                </span>
                <span className={s.partnersItem}>
                  <StaticImage
                    placeholder="none"
                    loading="eager"
                    alt="grill-express"
                    src="./images/grill-express.png"
                    className="w-auto"
                  />
                </span>
                <span className={s.partnersItem}>
                  <StaticImage
                    placeholder="none"
                    loading="eager"
                    alt="uncle-guiseppes"
                    src="./images/uncle-guiseppes.png"
                    className="w-auto"
                  />
                </span>
                <span className={s.partnersItem}>
                  <StaticImage
                    placeholder="none"
                    loading="eager"
                    alt="krispy-krunchy-chicken"
                    src="./images/krispy-krunchy-chicken.png"
                    className="w-auto"
                  />
                </span>
                <span className={s.partnersItem}>
                  <StaticImage
                    placeholder="none"
                    loading="eager"
                    alt="dickeys-barbecue-pit"
                    src="./images/dickeys-barbecue-pit.png"
                    className="w-auto"
                  />
                </span>

                <Link className={s.partnersItem} to="/customer-stories-pops-artisanal-creamery">
                  <StaticImage
                    placeholder="none"
                    loading="eager"
                    alt="pops-creamery"
                    src="./images/pops-creamery.png"
                    className="w-auto"
                  />
                </Link>

                <Link to="/customer-stories" className={s.partnersItem}>
                  <StaticImage
                    placeholder="none"
                    loading="eager"
                    alt="slash-pizza"
                    src="./images/slash-pizza.png"
                    className="w-auto"
                  />
                </Link>

              </div>
            </div>
          </div>
        </div>
        <div className={s.showMore}>
          <button type="button" onClick={() => { setOpened(true) }}>Show More</button>
        </div>
      </section>
    </>
  );
};

export default Partners;
