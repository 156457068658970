import { Link } from "gatsby";
import React from "react";
import * as s from "./how-it-works.module.css";
import TrialButton from './../../trialButton/trialButton';
import { StaticImage } from "gatsby-plugin-image";

const HowItWorks = ({ title, subtitle, scrollToForm }) => {
  return (
    <>
      <section className={s.howItWorks}>
        <StaticImage
          placeholder="none"
          loading="eager"
          alt="Form bg"
          src="./images/bg.png"
          className={`${s.bg} d-md-none`}
        />
        <div className="container">
          <div className="row">
            <div className="col-12">
              {!!title &&
                <h2 className={s.title}>
                  {title}
                </h2>
              }
              {!!subtitle &&
                <h3 className={s.subtitle}>
                  {subtitle}
                </h3>
              }
            </div>
            <div className="col-12 text-center">
              <StaticImage
                placeholder="none"
                loading="eager"
                alt="Steps"
                src="./images/steps-desktop.png"
                className="mw-100 d-none d-md-inline-block"
              />
              <StaticImage
                placeholder="none"
                loading="eager"
                alt="Steps"
                src="./images/steps-mobile.png"
                className="mw-100 d-md-none"
              />
            </div>
            <div className="col-12 d-flex justify-content-center mt-5">
              {!!scrollToForm &&
                <button
                  onClick={scrollToForm}
                  className={`${s.button}`}
                >
                  Get Started
                </button>
              }
            </div>
          </div>
        </div>
      </section>

    </>
  );
};

export default HowItWorks;
