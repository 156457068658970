import React from "react";
import * as s from "./features.module.css";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

const Features = ({ title }) => {


  return (
    <>
      <section className={`${s.features}`}>
        <StaticImage
          placeholder="none"
          loading="eager"
          alt="Form bg"
          src="./images/bg.png"
          className={s.bg}
        />
        <div className="container">
          <div className="row">
            <div className="col-12">
              {!!title &&
                <h2 className={s.title}>
                  {title}
                </h2>
              }
            </div>
            <div className="col px-0">
              <div className={s.items}>
                <Link to="/menu-management" className={s.item}>
                  <StaticImage
                    placeholder="none"
                    loading="eager"
                    alt="menu-management"
                    src="./images/menu-management.png"
                    className="w-auto"
                  />
                  <span className={s.itemTitle}>Menu<br /> Management</span>
                </Link>
                <Link to="/orders-consolidation" className={s.item}>
                  <StaticImage
                    placeholder="none"
                    loading="eager"
                    alt="orders-consolidation"
                    src="./images/orders-consolidation.png"
                    className="w-auto"
                  />
                  <span className={s.itemTitle}>Orders<br /> Consolidation</span>
                </Link>
                <Link to="/custom-ordering-website" className={s.item}>
                  <StaticImage
                    placeholder="none"
                    loading="eager"
                    alt="custom-ordering-website"
                    src="./images/custom-ordering-website.png"
                    className="w-auto"
                  />
                  <span className={s.itemTitle}>Custom Ordering<br /> Website</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Features;
