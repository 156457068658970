import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import * as s from "./top.module.css";

const Top = ({
  title,
  subtitle,
  image,
  button,
  styles,
}) => {
  return (
    <section className={`${s.topBlock}`} style={styles}>
      <StaticImage
        placeholder="none"
        loading="eager"
        alt="Form bg"
        src="./bg.png"
        className={s.bg}
      />
      <div className="container">
        <div className="row justify-content-between">
          <div className="col-12 d-flex flex-column justify-content-center">
            {!!title && <h1 className={`title order-1 ${s.title}`}>{title}</h1>}
            {!!subtitle && <div className={`${s.subtitle} order-md-2`}>{subtitle}</div>}
            {!!button && <div className={`${s.button} order-4 order-md-3`}>{button}</div>}
            <div className="mt-mb-4 mt-3 mb-md-5 mb-4 d-flex justify-content-center order-3 order-md-4">
              {image}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Top;
