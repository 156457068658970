import React from "react";
import * as s from "./delivery.module.css";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

const Delivery = () => {

  return (
    <>
      <section className={`${s.delivery}`}>
        <StaticImage
          placeholder="none"
          loading="eager"
          alt="Form bg"
          src="./images/bg.png"
          className={s.bg}
        />
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2 className={s.title}>Delivery Everywhere</h2>
            </div>
            <div className="col-12">
              <div className={s.deliveryItems}>
                <span className={s.deliveryItem}>
                  <StaticImage
                    placeholder="none"
                    loading="eager"
                    alt="doordash"
                    src="./images/doordash.png"
                    className={`${s.img}`}
                  />
                </span>
                <span className={s.deliveryItem}>
                  <StaticImage
                    placeholder="none"
                    loading="eager"
                    alt="ubereats"
                    src="./images/ubereats.png"
                    className={`${s.img}`}
                  />
                </span>
                <span className={s.deliveryItem}>
                  <StaticImage
                    placeholder="none"
                    loading="eager"
                    alt="postmates"
                    src="./images/postmates.png"
                    className={`${s.img}`}
                  />
                </span>
                <span className={s.deliveryItem}>
                  <StaticImage
                    placeholder="none"
                    loading="eager"
                    alt="ezcater"
                    src="./images/ezcater.png"
                    className={`${s.img}`}
                  />
                </span>
                <span className={s.deliveryItem}>
                  <StaticImage
                    placeholder="none"
                    loading="eager"
                    alt="deliverycom"
                    src="./images/deliverycom.png"
                    className={`${s.img}`}
                  />
                </span>
                <span className={s.deliveryItem}>
                  <StaticImage
                    placeholder="none"
                    loading="eager"
                    alt="doocado"
                    src="./images/doocado.png"
                    className={`${s.img}`}
                  />
                </span>
                <span className={s.deliveryItem}>
                  <StaticImage
                    placeholder="none"
                    loading="eager"
                    alt="grubhub"
                    src="./images/grubhub.png"
                    className={`${s.img}`}
                  />
                </span>
                <span className={s.deliveryItem}>
                  <StaticImage
                    placeholder="none"
                    loading="eager"
                    alt="eatstreet"
                    src="./images/eatstreet.png"
                    className={`${s.img}`}
                  />
                </span>
                <span className={s.deliveryItem}>
                  <StaticImage
                    placeholder="none"
                    loading="eager"
                    alt="slice"
                    src="./images/slice.png"
                    className={`${s.img}`}
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Delivery;
